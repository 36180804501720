import React from "react"
import "./price.module.scss"
import headerStyles from "./price.module.scss"

const Price = () => {

	return (

                <div className={ `uk-section uk-section-medium` } >
                <div className={ `uk-container` } >
              
                    <h2 className={ `${headerStyles.cardHeaders}` } >Стоимость юридических услуг</h2>
              
                    <p className={ `${headerStyles.cardDescriptions}` } >Стоимость юридических услуг зависит от вида помощи, интеллектуальных и материальных затрат, судебной инстанции, затраченного времени и других нюансов, о которых наши клиенты узнают до того, как подписан договор о сотрудничестве.</p>
              
                    <div className={ `uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-1@l uk-child-width-1-1@xl uk-margin-medium-top` } uk-grid="true"> 
              
                        <div>
                            <div>
              
                              <table className={ `uk-table ${headerStyles.ukTableStriped}` }>
                                <thead className={ `${headerStyles.colorTab}` }>
                                    <tr>
                                        <th className={ `${headerStyles.colorTh}` } >Юридическая консультация</th>
                                        <th className={ `uk-width-small ${headerStyles.colorTh}` }>Стоимость</th>
                                    </tr>
                                </thead>
              
                                <tbody>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Первичная консультация по телефону</td>
                                        <td className={ `${headerStyles.colorTd}` }>Бесплатно</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Юридическая консультация</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 1 000 ₽/час</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Письменная юридическая консультация</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 3 000 ₽</td>
                                    </tr>
                                </tbody>
              
                                <thead className={ `${headerStyles.colorTab}` } >
                                    <tr>
                                        <th className={ `${headerStyles.colorTh}` } >Составление правовых документов</th>
                                        <th className={ `uk-width-small ${headerStyles.colorTh}` } >Стоимость</th>
                                    </tr>
                                </thead>
              
                                <tbody>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Составление юридических документов (жалобы, претензии, заключения, возражения, ходатайства и др.)</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 5 000 ₽</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Составление эксклюзивных и индивидуальных договоров и контрактов</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 10 000 ₽</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Подготовка и составление процессуальных документов (за исключением искового заявления)</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 10 000 ₽</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Проведение проверок и экспертиз юридических документов</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 10 000 ₽</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Подготовка и составление искового заявления</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 10 000 ₽</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Подготовка и составление процессуальных документов (за исключением искового заявления)</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 5 000 ₽</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Ведение исполнительного производства</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 15 000 ₽</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Сопровождение договорных сделок</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 20 000 ₽</td>
                                    </tr>
                                </tbody>
              
                                <thead className={ `${headerStyles.colorTab}` }>
                                    <tr>
                                        <th className={ `${headerStyles.colorTh}` } >Представление интересов в суде</th>
                                        <th className={ `uk-width-small ${headerStyles.colorTh}` } >Стоимость</th>
                                    </tr>
                                </thead>
              
                                <tbody>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Представительство интересов клиента в мировом суде</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 10 000 ₽</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Представительство интересов клиента в районном суде</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 15 000 ₽</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Представительство в суде высшей инстанции аппеляционной/кассационной</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 25 000 ₽</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Подача аппеляции при судебном решении, которое не устраивает клиента</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 10 000 ₽</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Ознакомление с материалами дела в суде</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 5 000 ₽</td>
                                    </tr>
                                </tbody>
              
              
                              <thead className={ `${headerStyles.colorTab}` } >
                                    <tr>
                                        <th className={ `${headerStyles.colorTh}` } >Услуги в сфере недвижимости</th>
                                        <th className={ `uk-width-small ${headerStyles.colorTh}` } >Стоимость</th>
                                    </tr>
                                </thead>
              
                                <tbody>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Подбор недвижимости</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 10 000 ₽</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Сопровождение сделок с коммерческой недвижимостью</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 30 000 ₽</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Сопровождение сделки купли-продажи квартиры</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 20 000 ₽</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Сопровождение сделок по аренде коммерческой недвижимости</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 20 000 ₽</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Помощь в реализации квартир, апартаментов, домов, участков, коммерции</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 50 000 ₽</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Проверка юридической чистоты недвижимости</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 15 000 ₽</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Регистрация права собственности на недвижимое имущество и сделок с ним</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 10 000 ₽</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Составление договоров: купли/продажи, мены, дарения, аренды</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 5 000 ₽</td>
                                    </tr>
                                    <tr>
                                        <td className={ `${headerStyles.colorTd}` } >Оформление ипотеки</td>
                                        <td className={ `${headerStyles.colorTd}` } >от 15 000 ₽</td>
                                    </tr>
                                </tbody>
              
                              </table>
                                                
                            </div>
                        </div>
                    
                    </div>
              
                </div>
              </div>
        
	)
}

export default Price


