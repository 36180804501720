import React from "react"
import "./users.module.scss"
import headerStyles from "./users.module.scss"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Users = () => {

	return (

        <div className={ `uk-section uk-section-medium ${headerStyles.Colorsection}` }>
            <div className={ `uk-container ${headerStyles.Fon}` }>

                <h2 className={ `${headerStyles.UsersHeader}` }>Сотрудники</h2>

                
                <div className={ `uk-grid-match uk-child-width-1-1@l` } uk-grid="true">
     
                    <div>      
                        <div className={ `uk-flex uk-flex-row uk-flex-center uk-position-relative` } >
                            <div uk-slider="autoplay: true; autoplay-interval: 2000;">
                                <div className={ `uk-position-relative` } >

                            
                                    <ul className={ `uk-slider-items uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-3@l uk-child-width-1-3@xl` }> 

                                        <li> 
                                            <div>
                                                <div className={ `uk-card uk-card-body uk-text-center uk-flex uk-flex-column uk-flex-middle  ${headerStyles.UsersCard}` } >
                                                    
                                                    <img src="/img/user1.png" alt="Нигматуллин Эдуард Ильдарович" width="200px" height="200px"></img>

                                                    <p className={ `${headerStyles.UsersCardName}` }>Нигматуллин Эдуард Ильдарович</p>
                                                    <p className={ `uk-flex uk-flex-row uk-flex-center ${headerStyles.UsersCardCompany}` }>Руководитель компании «Алмаз»</p>
                                                    <p className={ `${headerStyles.UsersCardPhone}` }>+7 (950) 318-29-18</p>
                                                    <p className={ `${headerStyles.UsersCardEmail}` }>nigm76@mail.ru</p>
                                                    <a href="#contactForm" uk-toggle="target: #contactForm" className={ `${headerStyles.UsersCardButton}` }>Задайте вопрос</a>
                                                    <OutboundLink id="yandexEvent" href="tel:+79503182918" className={ `${headerStyles.UsersCardButton2}` }>
                                                        👉 ПОЗВОНИТЕ НАМ
                                                    </OutboundLink>
                                                </div>
                                            </div>

                                        </li>        

                                        <li> 
                                            <div>
                                                <div className={ `uk-card uk-card-body uk-text-center uk-flex uk-flex-column uk-flex-middle  ${headerStyles.UsersCard}` } >
                                                    
                                                    <img src="/img/user2.png" alt="Бабанова Юлия Олеговна" width="200px" height="200px"></img>

                                                    <p className={ `${headerStyles.UsersCardName}` }>Бабанова Юлия Олеговна</p>
                                                    <p className={ `uk-flex uk-flex-row uk-flex-center ${headerStyles.UsersCardCompany}` }>Зам.руководителя компании «Алмаз»</p>
                                                    <p className={ `${headerStyles.UsersCardPhone}` }>+7 (965) 607-57-44</p>
                                                    <p className={ `${headerStyles.UsersCardEmail}` }>babanovakazan@gmail.com</p>
                                                    <a href="#contactForm" uk-toggle="target: #contactForm" className={ `${headerStyles.UsersCardButton}` }>Задайте вопрос</a>
                                                    <OutboundLink id="yandexEvent" href="tel:+79656075744" className={ `${headerStyles.UsersCardButton2}` }>
                                                        👉 ПОЗВОНИТЕ НАМ
                                                    </OutboundLink>
                                                </div>
                                            </div>

                                        </li>        

                                        <li> 
                                            <div>
                                                <div className={ `uk-card uk-card-body uk-text-center uk-flex uk-flex-column uk-flex-middle  ${headerStyles.UsersCard}` } >
                                                    
                                                    <img src="/img/user3.png" alt="Бабанов Данила Михайлович" width="200px" height="200px"></img>

                                                    <p className={ `${headerStyles.UsersCardName}` }>Бабанов Данила Михайлович</p>
                                                    <p className={ `uk-flex uk-flex-row uk-flex-center  ${headerStyles.UsersCardCompany}` }>Разработка сайтов и мобильных приложений</p>
                                                    <p className={ `${headerStyles.UsersCardPhone}` }>+7 (939) 503-16-37</p>
                                                    <p className={ `${headerStyles.UsersCardEmail}` }>hi@danilababanov.ru</p>
                                                    <a href="#contactForm" uk-toggle="target: #contactForm" className={ `${headerStyles.UsersCardButton}` }>Задайте вопрос</a>
                                                    <OutboundLink id="yandexEvent" href="tel:+79395031637" className={ `${headerStyles.UsersCardButton2}` }>
                                                        👉 ПОЗВОНИТЕ НАМ
                                                    </OutboundLink>

                                                </div>
                                            </div>

                                        </li>        
                                        
                                    </ul>

                                </div>

                            </div>                                         
                        </div>
                    </div>
                </div>

            </div>
        </div>
        
	)
}

export default Users


