import React from "react"
import "./otzivBlank.module.scss"
import headerStyles from "./otzivBlank.module.scss"

const OtzivBlank = () => {

	return (

        <div className={ `uk-section uk-section-medium ${headerStyles.Colorsection}` }>
            <div className={ `uk-container` } >

                <h2 className={ `${headerStyles.otzivBlankHeader}` }>Рекомендательные письма</h2>

                <div className={ `uk-grid-match uk-child-width-1-1@l uk-margin-medium-top` } uk-grid="true">
                    <div>
                        <div className={ `uk-flex uk-flex-row uk-flex-center` } >

                            <div uk-slider="autoplay: true; autoplay-interval: 2000;">

                                <div className={ `uk-position-relative` }>
                                
                                    <div className={ `uk-slider-container uk-light` } >
                                        <ul uk-lightbox="autoplay: 2000;" className={ `uk-slider-items uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-3@l uk-child-width-1-3@xl` } >

                                            <li className={ `${headerStyles.otzivBlankLi} uk-flex uk-flex-center` }>
                                                <a href="/img/otzivblank1.png">
                                                    <img src="/img/otzivblank1.png" alt="отзыв агентства недвижимости Алмаз" className={ `${headerStyles.otzivBlankImg}` }></img>
                                                </a>
                                                <div className={ `uk-position-center uk-panel` }></div>
                                            </li>

                                            <li className={ `${headerStyles.otzivBlankLi} uk-flex uk-flex-center` }>
                                                <a href="/img/otzivblank2.png">
                                                    <img src="/img/otzivblank2.png" alt="отзыв агентства недвижимости Алмаз" className={ `${headerStyles.otzivBlankImg}` }></img>
                                                </a>
                                                <div className={ `uk-position-center uk-panel` }></div>
                                            </li>

                                            <li className={ `${headerStyles.otzivBlankLi} uk-flex uk-flex-center` }>
                                                <a href="/img/otzivblank3.png">
                                                    <img src="/img/otzivblank3.png" alt="отзыв агентства недвижимости Алмаз" className={ `${headerStyles.otzivBlankImg}` }></img>
                                                </a>
                                                <div className={ `uk-position-center uk-panel` }></div>
                                            </li>

                                            <li className={ `${headerStyles.otzivBlankLi} uk-flex uk-flex-center` }>
                                                <a href="/img/otzivblank4.png">
                                                    <img src="/img/otzivblank4.png" alt="отзыв агентства недвижимости Алмаз" className={ `${headerStyles.otzivBlankImg}` }></img>
                                                </a>
                                                <div className={ `uk-position-center uk-panel` }></div>
                                            </li>

                                            <li className={ `${headerStyles.otzivBlankLi} uk-flex uk-flex-center` }>
                                                <a href="/img/otzivblank5.png">
                                                    <img src="/img/otzivblank5.png" alt="отзыв агентства недвижимости Алмаз" className={ `${headerStyles.otzivBlankImg}` }></img>
                                                </a>
                                                <div className={ `uk-position-center uk-panel` }></div>
                                            </li>
                                            
                                        </ul>
                                    </div>

                                </div>

                            </div>
                        
                        </div>
                    </div>
                </div>

            </div>
        </div>

	)
}

export default OtzivBlank


