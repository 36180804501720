import React from "react"
import "./otzivSocial.module.scss"
import headerStyles from "./otzivSocial.module.scss"

const otzivSocial = () => {

	return (

        <div className={ `uk-section uk-section-small` }>
            <div className={ `uk-container ${headerStyles.Fon}` } >

                <h2 className={ `${headerStyles.otzivSocialHeader}` }>Отзывы</h2>

                <div className={ `uk-grid-match uk-child-width-1-1@l uk-margin-medium-top` } uk-grid="true">
                    <div>
                        <div className={ `uk-flex uk-flex-row uk-flex-center` } >

                            <div uk-slider="autoplay: true; autoplay-interval: 2000;">

                                <div className={ `uk-position-relative` }>

                                    <div className={ `uk-slider-container uk-light` } >
                                        <ul uk-lightbox="true" className={ `uk-slider-items uk-child-width-1-1 uk-child-width-1-3@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-4@xl` } >

                                            <li className={ `${headerStyles.otzivSocialLi} uk-flex uk-flex-center` }>
                                                <a href="/img/os1.png">
                                                    <img className={ `${headerStyles.otzivSocialImg}` } src="/img/os1.png" alt="отзыв агентства недвижимости Алмаз"></img>
                                                </a>
                                                <div className={ `uk-position-center uk-panel` }></div>
                                            </li>

                                            <li className={ `${headerStyles.otzivSocialLi} uk-flex uk-flex-center` }>
                                                <a href="/img/os2.png">
                                                    <img className={ `${headerStyles.otzivSocialImg}` } src="/img/os2.png" alt="отзыв агентства недвижимости Алмаз"></img>
                                                </a>
                                                <div className={ `uk-position-center uk-panel` }></div>
                                            </li>

                                            <li className={ `${headerStyles.otzivSocialLi} uk-flex uk-flex-center` }>
                                                <a href="/img/os3.png">
                                                    <img className={ `${headerStyles.otzivSocialImg}` } src="/img/os3.png" alt="отзыв агентства недвижимости Алмаз"></img>
                                                </a>
                                                <div className={ `uk-position-center uk-panel` }></div>
                                            </li>

                                            <li className={ `${headerStyles.otzivSocialLi} uk-flex uk-flex-center` }>
                                                <a href="/img/os4.png">
                                                    <img className={ `${headerStyles.otzivSocialImg}` } src="/img/os4.png" alt="отзыв агентства недвижимости Алмаз"></img>
                                                </a>
                                                <div className={ `uk-position-center uk-panel` }></div>
                                            </li>

                                            <li className={ `${headerStyles.otzivSocialLi} uk-flex uk-flex-center` }>
                                                <a href="/img/os5.png">
                                                    <img className={ `${headerStyles.otzivSocialImg}` } src="/img/os5.png" alt="отзыв агентства недвижимости Алмаз"></img>
                                                </a>
                                                <div className={ `uk-position-center uk-panel` }></div>
                                            </li>

                                            <li className={ `${headerStyles.otzivSocialLi} uk-flex uk-flex-center` }>
                                                <a href="/img/os6.png">
                                                    <img className={ `${headerStyles.otzivSocialImg}` } src="/img/os6.png" alt="отзыв агентства недвижимости Алмаз"></img>
                                                </a>
                                                <div className={ `uk-position-center uk-panel` }></div>
                                            </li>
                                            
                                        </ul>
                                    </div>

                                </div>

                            </div>
                        
                        </div>
                    </div>
                </div>

            </div>
        </div>

	)
}

export default otzivSocial


