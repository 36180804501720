import React from "react"
import "./map.module.scss"
import headerStyles from "./map.module.scss"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const Map = () => {

	return (

        <div className={ `uk-section.uk-section-xsmall ${headerStyles.MapMobileHiden }` }>

            <div className={ `uk-container` }>
                <div className={ `uk-grid-match uk-child-width-1-1@l uk-margin-medium-top` } uk-grid="true">

                    <h2 className={ `${headerStyles.MapHeader}` }>🚕 Приезжайте к нам в офис</h2>
                    <p className={ `${headerStyles.MapHeaderDescription}` }>Давайте встретимся у нас в офисе и все обсудим за чашкой кофе</p>
                
                </div>
            </div>

            <div className={ `uk-container uk-position-relative uk-margin-medium-top` }>

                <form id="mapForm2" className={ `${headerStyles.MapForm}` }>
                    <fieldset className={ `uk-fieldset` } >

                        <div className={`uk-text-center ${headerStyles.MapFormTop15} `}>
                            <img src="/img/call.png" width="220" height="220"></img>
                        </div>

                        <div className={ `uk-margin uk-flex uk-flex-row uk-flex-center` }>
                            <p className={ `${headerStyles.MapFormTitle}` }>Оставьте свой номер телефона</p>
                        </div>
                        
                        <div className={ `uk-margin uk-flex uk-flex-row uk-flex-center` }>
                            <p className={ `${headerStyles.MapFormNumber}` } >Мы перезвоним Вам в течение 1 минуты!</p>
                        </div>

                        <div className={ `uk-margin uk-flex uk-flex-row uk-flex-center` }>
                            <div className={ `uk-inline` } >                                           
                                <img className={ `${headerStyles.MapFormInputSvg}` } src="/svg/rus.svg" width="21" height="14" uk-svg-rus="true"></img>

                                <input className={ ` ${headerStyles.MapFormInput}` } type="text" placeholder="Ваш номер телефона"></input>
                            </div>
                        </div>

                        <div className={ `uk-margin uk-flex uk-flex-row uk-flex-center uk-flex-middle` }>
                            
                            <input className={ `uk-checkbox` } type="checkbox"></input>
                            
                            <p className={ `${headerStyles.MapFormPolicy} uk-margin-small-left` } >Нажимая на кнопку "ЗАКАЗАТЬ ЗВОНОК", вы даете согласие на <a href="/obrabotka" target="_blank" className={ `${headerStyles.MapFormPolicyLink}` }> обработку своих персональных данных </a> и соглашаетесь с <a href="/policy" target="_blank" className={ `${headerStyles.MapFormPolicyLink}` }>пользовательским соглашением</a></p>
                        </div>

                        <div className={ `uk-margin uk-flex uk-flex-row uk-flex-center` }>
                            <button id="yandexEvent" className={ `uk-button uk-button-default ${headerStyles.MapFormButton}` }
                                onClick={e => {
                                    trackCustomEvent({
                                    category: "form",
                                    action: "click",
                                    label: "Gatsby Plugin Example Campaign"
                                    })
                                }}
                                >ЗАКАЗАТЬ ЗВОНОК
                            </button>
                        </div>

                    </fieldset>
                </form>

                <img className={ `${headerStyles.MapImgPhone}` } src="/img/phone.png" alt=""></img>

            </div>
         
            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A6bb914e8db28d2bc505f795dfd8cc79bdb71c4eec98fdf4ff55859de4f5064d5&amp;source=constructor" width="100%" height="700"></iframe>
           
        </div>


	)
}

export default Map


