import React from "react"
import "./otzivAudio.module.scss"
import headerStyles from "./otzivAudio.module.scss"

const otzivAudio = () => {

	return (

        <div className={ `uk-section uk-section-medium` }>
            <div className={ `uk-container` }>

                <h2 className={ `${headerStyles.otzivAudioHeader}` }>Аудио отзывы</h2>

                
                <div className={ `uk-grid-match uk-child-width-1-1@l uk-margin-small-top` } uk-grid="true">
     
                    <div>      
                        <div className={ `uk-flex uk-flex-row uk-flex-center uk-position-relative` } >
                            <div uk-slider="autoplay: true; autoplay-interval: 2000;">
                                <div className={ `uk-position-relative uk-visible-toggle uk-light` }  tabIndex="-1">

                                    <ul className={ `uk-slider-items uk-child-width-1-1@s uk-child-width-1-1@m uk-child-width-1-2@l uk-child-width-1-2@xl uk-grid uk-grid-small` } > 

                                        <li className={ `${headerStyles.otzivAudioCardLi}`}> 
                                            <div>
                                                <div className={ `uk-card uk-card-default uk-card-body ${headerStyles.otzivAudioCard}` } >
                                                    <div className={ `${headerStyles.imgLine1}` }></div>
                                                    <div className={ `uk-grid-match` } uk-grid="true" >
                                                        <div className={ `uk-width-1-3@l uk-width-1-1@s uk-width-1-1@m` }  >

                                                            <div className="uk-text-center ">
                                                                <img src="/img/oa1.png" alt="отзывы агентства недвижимости алмаз" className={ `${headerStyles.otzivAudioImg}` }></img>
                                                            
                                                                <p className={ `${headerStyles.otzivAudioName}` }>Мария Семенова</p>

                                                                <div className={ `uk-text-center uk-margin-small-top` }>
                                                                    <p className={ `${headerStyles.otzivAudioCity}` }>г.Казань</p>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>

                                                        <div className={ `uk-width-2-3@l uk-width-1-1@s uk-width-1-1@m` } className={ `${headerStyles.otzivMobileCardMargin}` }>
                                                            <div>
                                                                <img className={ `${headerStyles.otzivMobileCardMarginImg}` } src="/svg/message.svg" uk-svg-message="true" alt="Агентство недвижимости Алмаз"></img>

                                                                <p className={ `${headerStyles.otzivAudioTitle}` }>
                                                                    <span className={ `${headerStyles.otzivAudioTitleLine}` }>Купить магазин</span> 
                                                                </p>
                                                                
                                                                <p className={ `uk-margin-small-top ${headerStyles.otzivAudioDescription}` }>Помогли с покупкой коммерческого помещения под магазин, профессионалы своего дела, оперативно подобрали вариант под все мои требования. Множество вариантов, не засвеченных на других площадках! Благодарю за профессионализм и оперативность, с компанией Алмаз очень приятно иметь дело!</p>
                                                                
                                                                <audio controls className={ `${headerStyles.otzivAudioAudio}` }>
                                                                    <source src="/audio/oa1.mp3" type="audio/mpeg"></source>
                                                                    Ваш браузер не поддерживается
                                                                </audio>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <li className={ `${headerStyles.otzivAudioCardLi}`}> 
                                            <div>
                                                <div className={ `uk-card uk-card-default uk-card-body ${headerStyles.otzivAudioCard}` } >
                                                    <div className={ `${headerStyles.imgLine2}` }></div>
                                                    <div className={ `uk-grid-match` } uk-grid="true" >
                                                        <div className={ `uk-width-1-3@l uk-width-1-1@s uk-width-1-1@m` } >
                                                            <div className="uk-text-center">
                                                                <img src="/img/oa2.png" alt="отзывы агентства недвижимости алмаз" className={ `${headerStyles.otzivAudioImg}` }></img>
                                                            
                                                                <p className={ `${headerStyles.otzivAudioName}` }>Олег Марфин</p>

                                                                <div className={ `uk-text-center uk-margin-small-top` }>
                                                                    <p className={ `${headerStyles.otzivAudioCity}` }>г.Казань</p>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>

                                                        <div className={ `uk-width-2-3@l uk-width-1-1@s uk-width-1-1@m` } className={ `${headerStyles.otzivMobileCardMargin}` }>
                                                            <div>
                                                                <img className={ `${headerStyles.otzivMobileCardMarginImg}` } src="/svg/message.svg" uk-svg-message="true" alt="Агентство недвижимости Алмаз"></img>

                                                                <p className={ `${headerStyles.otzivAudioTitle}` }>
                                                                    <span className={ `${headerStyles.otzivAudioTitleLine}` }>Продажа квартиры</span> 
                                                                </p>
                                                                
                                                                <p className={ `uk-margin-small-top ${headerStyles.otzivAudioDescription}` }>Я продавал квартиру в новостройке ЖК Сказочный лес с агентством недвижимости Алмаз и остался очень доволен. Покупатель нашёлся очень быстро, юрист Юлия сопровождала меня на всех этапах сделки: при заключении предварительного договора, в банке и в МФЦ. С подготовкой документов при регистрации проблем не возникло. У меня остались самые приятные впечатления от нашего знакомства и совместной работы. </p>
                                                                
                                                                <audio controls className={ `${headerStyles.otzivAudioAudio}` }>
                                                                    <source src="/audio/oa2.mp3" type="audio/mpeg"></source>
                                                                    Ваш браузер не поддерживается
                                                                </audio>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <li className={ `${headerStyles.otzivAudioCardLi}`}> 
                                            <div>
                                                <div className={ `uk-card uk-card-default uk-card-body ${headerStyles.otzivAudioCard}` } >
                                                    <div className={ `${headerStyles.imgLine3}` }></div>
                                                    <div className={ `uk-grid-match` } uk-grid="true" >
                                                        <div className={ `uk-width-1-3@l uk-width-1-1@s uk-width-1-1@m` } >
                                                            <div className="uk-text-center">
                                                                <img src="/img/oa3.png" alt="отзывы агентства недвижимости алмаз" className={ `${headerStyles.otzivAudioImg}` }></img>
                                                            
                                                                <p className={ `${headerStyles.otzivAudioName}` }>Айгуль Мустафина</p>

                                                                <div className={ `uk-text-center uk-margin-small-top` }>
                                                                    <p className={ `${headerStyles.otzivAudioCity}` }>г.Казань</p>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>

                                                        <div className={ `uk-width-2-3@l uk-width-1-1@s uk-width-1-1@m` } className={ `${headerStyles.otzivMobileCardMargin}` }>
                                                            <div>
                                                                <img className={ `${headerStyles.otzivMobileCardMarginImg}` } src="/svg/message.svg" uk-svg-message="true" alt="Агентство недвижимости Алмаз"></img>
                                                            
                                                                <p className={ `${headerStyles.otzivAudioTitle}` }>
                                                                    <span className={ `${headerStyles.otzivAudioTitleLine}` }>Продажа квартиры</span> 
                                                                </p>
                                                                
                                                                <p className={ `uk-margin-small-top ${headerStyles.otzivAudioDescription}` }>Остались только положительные впечатление от работы агентства недвижимости Алмаз. Продали мою квартиру в кратчайшие сроки и за ту сумму, которую я хотела. На текущий момент продолжаю с ними работать, уже по поиску аренды офиса. Крайне рекомендую данное агентство и его представителей.</p>
                                                                
                                                                <audio controls className={ `${headerStyles.otzivAudioAudio}` }>
                                                                    <source src="/audio/oa3.mp3" type="audio/mpeg"></source>
                                                                        Ваш браузер не поддерживается
                                                                </audio>
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <li className={ `${headerStyles.otzivAudioCardLi}`}> 
                                            <div>
                                                <div className={ `uk-card uk-card-default uk-card-body ${headerStyles.otzivAudioCard}` } >
                                                    <div className={ `${headerStyles.imgLine4}` }></div>
                                                    <div className={ `uk-grid-match` } uk-grid="true" >
                                                        <div className={ `uk-width-1-3@l uk-width-1-1@s uk-width-1-1@m` } > 
                                                            <div className="uk-text-center">
                                                                <img src="/img/oa4.png" alt="отзывы агентства недвижимости алмаз" className={ `${headerStyles.otzivAudioImg}` }></img>
                                                            
                                                                <p className={ `${headerStyles.otzivAudioName}` }>Татьяна Иванова</p>

                                                                <div className={ `uk-text-center uk-margin-small-top` }>
                                                                    <p className={ `${headerStyles.otzivAudioCity}` }>г.Казань</p>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>

                                                        <div className={ `uk-width-2-3@l uk-width-1-1@s uk-width-1-1@m` } className={ `${headerStyles.otzivMobileCardMargin}` }>
                                                            <div>
                                                                <img className={ `${headerStyles.otzivMobileCardMarginImg}` } src="/svg/message.svg" uk-svg-message="true" alt="Агентство недвижимости Алмаз"></img>

                                                                <p className={ `${headerStyles.otzivAudioTitle}` }>
                                                                    <span className={ `${headerStyles.otzivAudioTitleLine}` }>Аренда помещения</span> 
                                                                </p>
                                                                
                                                                <p className={ `uk-margin-small-top ${headerStyles.otzivAudioDescription}` }>Советую всем агентство недвижимости Алмаз! Знакомые посоветовали именно эту компанию. Хотели взять в аренду помещение, но очень боялись это сделать. Ребята очень добросовестно отнеслись к нашей ситуации и помогли абсолютно во всём. От них поступало много различных предложений по поводу аренды, помогали с местом и работали с нами как с лучшими друзьями. Теперь, если что, только к вам! Успехов!</p>
                                                                
                                                                <audio controls className={ `${headerStyles.otzivAudioAudio}` }>
                                                                    <source src="/audio/oa4.mp3" type="audio/mpeg"></source>
                                                                    Ваш браузер не поддерживается
                                                                </audio>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <li className={ `${headerStyles.otzivAudioCardLi}`}> 
                                            <div>
                                                <div className={ `uk-card uk-card-default uk-card-body ${headerStyles.otzivAudioCard}` } >
                                                    <div className={ `${headerStyles.imgLine5}` }></div>
                                                    <div className={ `uk-grid-match` } uk-grid="true" >
                                                        <div className={ `uk-width-1-3@l uk-width-1-1@s uk-width-1-1@m` } > 
                                                            <div className="uk-text-center">
                                                                <img src="/img/oa5.png" alt="отзывы агентства недвижимости алмаз" className={ `${headerStyles.otzivAudioImg}` }></img>
                                                            
                                                                <p className={ `${headerStyles.otzivAudioName}` }>Анна Плотникова</p>

                                                                <div className={ `uk-text-center uk-margin-small-top` }>
                                                                    <p className={ `${headerStyles.otzivAudioCity}` }>г.Казань</p>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>

                                                        <div className={ `uk-width-2-3@l uk-width-1-1@s uk-width-1-1@m` } className={ `${headerStyles.otzivMobileCardMargin}` }>
                                                            <div>
                                                                <img className={ `${headerStyles.otzivMobileCardMarginImg}` } src="/svg/message.svg" uk-svg-message="true" alt="Агентство недвижимости Алмаз"></img>
                                                                
                                                                <p className={ `${headerStyles.otzivAudioTitle}` }>
                                                                    <span className={ `${headerStyles.otzivAudioTitleLine}` }>Аренда магазина</span> 
                                                                </p>
                                                                
                                                                <p className={ `uk-margin-small-top ${headerStyles.otzivAudioDescription}` }>Выражаю, огромную благодарность агентству недвижимости Алмаз за то, что быстро нашли место для аренды моего магазина, которое я так давно искала. Учли все мои пожелания, и нашли самый выгодный вариант. И по цене, и по площади. Не возникло никаких проблем с документами. Спасибо за проделанную работу.</p>
                                                                
                                                                <audio controls className={ `${headerStyles.otzivAudioAudio}` }>
                                                                    <source src="/audio/oa5.mp3" type="audio/mpeg"></source>
                                                                        Ваш браузер не поддерживается
                                                                </audio>
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
    
                                    </ul>

                                </div>

                            </div>                                         
                        </div>
                    </div>
                </div>    
                
            </div>
        </div>
        
	)
}

export default otzivAudio


