import React from "react"
import "./header.module.scss"
import headerStyles from "./header.module.scss"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Header = (props) => {
	return (
        <header>
            <div className={ `${headerStyles.fullScreenDiv}` }>
                <div className={ `${headerStyles.videoDiv}` }>           
                  <video className={ `${headerStyles.video}` } src="/video/kzn.mp4" loop muted playsinline uk-video="autoplay: inview"></video>
                </div>

                <div className={ `${headerStyles.aboutFooter}` }></div>

                <div className={ `${headerStyles.contentBox}` } > 
                        <div className={ `uk-section uk-section-overlap uk-section-small` } >
                            <div className={ `uk-container` }>

                            <div className={ `uk-grid-match uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-2@l uk-child-width-1-2@xl` } uk-grid="true">

                                <div className={ `uk-flex uk-flex-row uk-flex-middle ${headerStyles.headerLogoFlex}` } >
                                    <a className={ `${headerStyles.headerLogo}` } href="/">
                                        <img className={ `uk-preserve uk-margin-small-right` } src="/svg/logo.svg" width="180" height="60" uk-svg-car></img>
                                     </a>
                                </div>

                                <div>
                                    <div className={ `uk-flex uk-flex-column uk-flex-right ${headerStyles.headerLogoFlex}` }>
                                        <span className={ `uk-flex uk-flex-right ${headerStyles.headerLogoFlex} ${headerStyles.headerMiniText}` }> 
                                        Бесплатный звонок по РФ
                                        </span>
                                    
                                        <div className={ `uk-flex uk-flex-row uk-flex-middle uk-flex-right ${headerStyles.headerLogoFlex}` }>
                                            <img className={ `uk-margin-small-right` } src="/svg/hphone.svg" width="30" height="28" uk-svg-w></img>
                                            <a className={ `${headerStyles.headerPhone}` } href="tel:88005519518">8 (800) 551-95-18</a> 
                                        </div>

                                        <div className={ `uk-flex uk-flex-row uk-flex-middle uk-margin-small-top uk-flex-right ${headerStyles.headerLogoFlex}` } >
                                            <img className={ `uk-margin-small-right` } src="/svg/hmap.svg" width="30" height="28" uk-svg-w></img>
                                            <a className={ `${headerStyles.headerText}` }>г.Казань, Щапова 26, этаж 5, офис 20</a>
                                        </div>
                                    </div>

                                </div>

                                </div>
                            </div>
                        </div>

                        <div className={ `uk-section uk-section-overlap uk-section-small ${headerStyles.containerText}` } >
                            <div className={ `uk-container` } >

                            <div className={ `uk-grid-small ${headerStyles.clearMarginTop}` } uk-grid="true">

                                <div className={ `uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l uk-width-1-1@xl` } >
                                
                                <div className={ `uk-flex uk-flex-center` } >
                                    <h1 className={ `${headerStyles.headerTitle} uk-text-center` } >
                                        <span className={ `${headerStyles.headerTitleLine}` }>{props.title}</span> 
                                    </h1>
                                </div>
                                
                                <p className={ `${headerStyles.headerDescription} uk-text-center` } >{props.card}</p>
                                
                                <div className={ `uk-margin-medium-top` } >
                                    <a uk-toggle="target: #contactForm" className={ `${headerStyles.headerButton} ${headerStyles.buttonLine} uk-margin-auto` }>
                                        <span >👉 Воспользоваться помощью юриста</span>
                                        <img className={ `uk-margin-small-left uk-preserve shake-little shake-constant shake-constant--hover` } src="/svg/finger.svg" width="32" height="32" uk-svg-lapa></img>
                                    </a>

                                    
                                    <OutboundLink id="yandexEvent" href="tel:88005519518" className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle ${headerStyles.headerButton2} ${headerStyles.sliderButton} ${headerStyles.sliderButtonLine} uk-margin-auto` }>
                                        👉 ПОЗВОНИТЕ НАМ
                                        <img alt="ПОЗВОНИТЕ НАМ" className={ `uk-preserve uk-margin-small-left shake-little shake-constant shake-constant--hover ${headerStyles.sliderFinger}` } src="/svg/finger.svg" width="38" height="38" uk-svg-finger="true"></img>
                                    </OutboundLink>
                                    
                                </div>

                                <div className={ `uk-margin-medium-top uk-flex uk-flex-center uk-flex-wrap ${headerStyles.headerLogoFlex}` } >

                                    <div className={ `uk-margin ${headerStyles.ukMarginSmallTopFix}` } >
                                        <div className={ `${headerStyles.pTextLink}` }>
                                            <div className={ `${headerStyles.pImgBox} uk-flex uk-flex-row uk-flex-center` }>
                                                <img className={ `${headerStyles.p1}` } src="/img/001.png" alt="Агентство недвижимости Алмаз"></img>
                                            </div>
                                            <p className={ `${headerStyles.pText}` } >👨🏻‍🎓 Наши специалисты имеют высшее юридическое образование и большой опыт работы госслужбы от 25 лет</p>
                                        </div>
                                    </div>

                                    <div className={ `uk-margin ${headerStyles.ukMarginSmallTopFix}` }>
                                        <div className={ `${headerStyles.pTextLink}` } >
                                            <div className={ `${headerStyles.pImgBox} uk-flex uk-flex-row uk-flex-center` } >
                                                <img className={ `${headerStyles.p1}` } src="/img/002.png" alt="Реализация поставок продовольственных товаров за рубеж"></img>
                                            </div>
                                            <p className={ `${headerStyles.pText}` } >👍 У нас нет колл-центра и менеджеров по продажам, Вы общаетесь напрямую с юристом </p>
                                        </div>
                                    </div>

                                    <div className={ `uk-margin ${headerStyles.ukMarginSmallTopFix}` }>
                                        <div className={ `${headerStyles.pTextLink}` } >
                                            <div className={ `${headerStyles.pImgBox} uk-flex uk-flex-row uk-flex-center` } >
                                                <img className={ `${headerStyles.p1}` } src="/img/003.png" alt="Реализация поставок продовольственных товаров за рубеж" ></img>

                                            </div>
                                            <p className={ `${headerStyles.pText}` }>🔥 Мы не занимаемся демпингом услуг, но в тоже время наши тарифы не превышают средний уровень рыночных цен</p>
                                        </div>
                                    </div>


                                </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>   
            </div>
        </header>
    )
}

export default Header
