import React from "react"
import "./social.module.scss"
import headerStyles from "./social.module.scss"

const Social = () => {

	return (

        <div className={ `uk-section.uk-section-xsmall uk-margin-medium-top ` }>
            <div className={ `uk-container ${headerStyles.Fon}` }>

                <h2 className={ `${headerStyles.SocialHeader}` }>Мы в социальных сетях</h2>
                
                <div className={ `uk-grid-match uk-child-width-1-1@l uk-margin-small-top` } uk-grid="true">
     
                    <div>      
                        <div className={ `uk-flex uk-flex-row uk-flex-center uk-position-relative` } >
                            <div uk-slider="autoplay: true; autoplay-interval: 2000;">
                                <div className={ `uk-position-relative uk-visible-toggle uk-light` } tabIndex="-1">

                                    <ul className={ `uk-slider-items uk-child-width-1-1@s uk-child-width-1-1@m uk-child-width-1-3@l uk-child-width-1-3@xl uk-grid uk-grid-small` } > 

                                        <li className={ `${headerStyles.SocialCardLi}`}> 
                                            <div>
                                                <div className={ `uk-card uk-card-default uk-card-body  ${headerStyles.SocialCard}` } >
                                                    
                                                    <div className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle` } >
                                                        <img alt="отзывы агентство недвижимости Алмаз" className={ `uk-preserve uk-margin-small-right` } src="/svg/sv.svg" width="36" height="36" uk-svg-sv="true"></img>

                                                        <span className={ `uk-margin-small-left ${headerStyles.SocialLink}` } >vk</span>

                                                        <a href="https://vk.com/knktatar" className={ `${headerStyles.SocialButtonLink}` }>Подписаться</a>
                                                    </div>  

                                                    <hr></hr>

                                                    <p className={ `${headerStyles.SocialCount}` } >С нами дружат более <b id="vk">0</b> человек</p>

                                                    <div className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle uk-flex-wrap` }>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social1.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social2.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social3.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social4.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social5.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social6.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                        </li>           

                                        <li className={ `${headerStyles.SocialCardLi}`}> 
                                            <div>
                                                <div className={ `uk-card uk-card-default uk-card-body  ${headerStyles.SocialCard}` } >
                                                    
                                                    <div className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle` } >
                                                        <img alt="отзывы агентство недвижимости Алмаз" className={ `uk-preserve uk-margin-small-right` } src="/svg/si.svg" width="36" height="36" uk-svg-si="true"></img>

                                                        <span className={ `uk-margin-small-left ${headerStyles.SocialLink}` } >instagram</span>

                                                        <a href="https://www.instagram.com/knktatar" className={ `${headerStyles.SocialButtonLink}` }>Подписаться</a>
                                                    </div>  

                                                    <hr></hr>

                                                    <p className={ `${headerStyles.SocialCount}` } >С нами дружат более <b id="instagram">0</b> человек</p>

                                                    <div className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle uk-flex-wrap` }>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social7.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social8.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social9.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social10.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social11.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social12.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                        </li>           



                                        <li className={ `${headerStyles.SocialCardLi}`}> 
                                            <div>
                                                <div className={ `uk-card uk-card-default uk-card-body  ${headerStyles.SocialCard}` } >
                                                    
                                                    <div className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle` } >
                                                        <img alt="" className={ `uk-preserve uk-margin-small-right` } src="/svg/sf.svg" width="36" height="36" uk-svg-sf="true"></img>

                                                        <span className={ `uk-margin-small-left ${headerStyles.SocialLink}` } >facebook</span>

                                                        <a href="https://www.facebook.com/knktatarkzn" className={ `${headerStyles.SocialButtonLink}` }>Подписаться</a>
                                                    </div>  

                                                    <hr></hr>

                                                    <p className={ `${headerStyles.SocialCount}` } >С нами дружат более <b id="fb">0</b> человек</p>

                                                    <div className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle uk-flex-wrap` }>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social13.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social14.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social15.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social16.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social17.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social18.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                        </li>           

                                        <li className={ `${headerStyles.SocialCardLi}`}> 
                                            <div>
                                                <div className={ `uk-card uk-card-default uk-card-body  ${headerStyles.SocialCard}` } >
                                                    
                                                    <div className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle` } >
                                                        <img alt="" className={ `uk-preserve uk-margin-small-right` } src="/svg/sy.svg" width="36" height="36" uk-svg-sy="true"></img>

                                                        <span className={ `uk-margin-small-left ${headerStyles.SocialLink}` } >youtube</span>

                                                        <a href="https://www.youtube.com/knktatar" className={ `${headerStyles.SocialButtonLink}` }>Подписаться</a>
                                                    </div>  

                                                    <hr></hr>

                                                    <p className={ `${headerStyles.SocialCount}` } >С нами дружат более <b id="youtube">0</b> человек</p>

                                                    <div className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle uk-flex-wrap` }>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social19.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social20.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social21.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social22.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social23.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social24.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                        </li>           


                                        <li className={ `${headerStyles.SocialCardLi}`}> 
                                            <div>
                                                <div className={ `uk-card uk-card-default uk-card-body  ${headerStyles.SocialCard}` } >
                                                    
                                                    <div className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle` } >
                                                        <img alt="отзывы агентство недвижимости Алмаз" className={ `uk-preserve uk-margin-small-right` } src="/svg/st.svg" width="36" height="36" uk-svg-st="true"></img>

                                                        <span className={ `uk-margin-small-left ${headerStyles.SocialLink}` } >twitter</span>

                                                        <a href="https://twitter.com/knktatar" className={ `${headerStyles.SocialButtonLink}` }>Подписаться</a>
                                                    </div>  

                                                    <hr></hr>

                                                    <p className={ `${headerStyles.SocialCount}` } >С нами дружат более <b id="twitter">0</b> человек</p>

                                                    <div className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle uk-flex-wrap` }>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social25.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social26.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social27.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social28.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social29.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social30.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                        </li>      

                                        <li className={ `${headerStyles.SocialCardLi}`}> 
                                            <div>
                                                <div className={ `uk-card uk-card-default uk-card-body  ${headerStyles.SocialCard}` } >
                                                    
                                                    <div className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle` } >
                                                        <img alt="отзывы агентство недвижимости Алмаз" className={ `uk-preserve uk-margin-small-right` } src="/svg/ok.svg" width="36" height="36" uk-svg-st="true"></img>

                                                        <span className={ `uk-margin-small-left ${headerStyles.SocialLink}` } >ok</span>

                                                        <a href="https://ok.ru/knktatar" className={ `${headerStyles.SocialButtonLink}` }>Подписаться</a>
                                                    </div>  

                                                    <hr></hr>

                                                    <p className={ `${headerStyles.SocialCount}` } >С нами дружат более <b id="ok">0</b> человек</p>

                                                    <div className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle uk-flex-wrap` }>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social37.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social38.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social39.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social40.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social41.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social42.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                        </li>    

                                        <li className={ `${headerStyles.SocialCardLi}`}> 
                                            <div>
                                                <div className={ `uk-card uk-card-default uk-card-body  ${headerStyles.SocialCard}` } >
                                                    
                                                    <div className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle` } >
                                                        <img alt="отзывы агентство недвижимости Алмаз" className={ `uk-preserve uk-margin-small-right` } src="/svg/tel.svg" width="36" height="36" uk-svg-st="true"></img>

                                                        <span className={ `uk-margin-small-left ${headerStyles.SocialLink}` } >telegram</span>

                                                        <a href="https://t.me/knktatar" className={ `${headerStyles.SocialButtonLink}` }>Подписаться</a>
                                                    </div>  

                                                    <hr></hr>

                                                    <p className={ `${headerStyles.SocialCount}` } >С нами дружат более <b id="telegram">0</b> человек</p>

                                                    <div className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle uk-flex-wrap` }>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social43.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social44.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social45.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social46.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social47.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social48.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                        </li>   

                                        <li className={ `${headerStyles.SocialCardLi}`}> 
                                            <div>
                                                <div className={ `uk-card uk-card-default uk-card-body  ${headerStyles.SocialCard}` } >
                                                    
                                                    <div className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle` } >
                                                        <img alt="отзывы агентство недвижимости Алмаз" className={ `uk-preserve uk-margin-small-right` } src="/svg/pin.svg" width="36" height="36" uk-svg-st="true"></img>

                                                        <span className={ `uk-margin-small-left ${headerStyles.SocialLink}` } >pinterest </span>

                                                        <a href="https://www.pinterest.ru/knktatar" className={ `${headerStyles.SocialButtonLink}` }>Подписаться</a>
                                                    </div>  

                                                    <hr></hr>

                                                    <p className={ `${headerStyles.SocialCount}` } >С нами дружат более <b id="pinterest">0</b> человек</p>

                                                    <div className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle uk-flex-wrap` }>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social49.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social50.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social51.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social52.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social53.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social54.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                    </div>

                                                    
                                                </div>
                                            </div>
                                        </li>       

                                        <li className={ `${headerStyles.SocialCardLi}`}> 
                                            <div>
                                                <div className={ `uk-card uk-card-default uk-card-body  ${headerStyles.SocialCard}` } >
                                                    
                                                    <div className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle` } >
                                                        <img alt="отзывы агентство недвижимости Алмаз" className={ `uk-preserve uk-margin-small-right` } src="/svg/x1.svg" width="36" height="36" uk-svg-st="true"></img>

                                                        <span className={ `uk-margin-small-left ${headerStyles.SocialLink}` } >Дзен </span>

                                                        <a href="https://zen.yandex.ru/id/5e5d52333f8d4a2b01fd08b1" className={ `${headerStyles.SocialButtonLink}` }>Подписаться</a>
                                                    </div>  

                                                    <hr></hr>

                                                    <p className={ `${headerStyles.SocialCount}` } >С нами дружат более <b id="zen">0</b> человек</p>

                                                    <div className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle uk-flex-wrap` }>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social55.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social56.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social57.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social58.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social59.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                        <img className={ `uk-margin-small-top uk-margin-small-right` } src="/img/social60.png" width="75px" height="89px" alt="отзывы агентство недвижимости Алмаз"></img>
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                        </li>                               
              
                                        
                                    </ul>

                                </div>

                            </div>                                         
                        </div>
                    </div>
                </div>

            </div>
        </div>
        
	)
}

export default Social


