import React from "react"
import "./catalog.module.scss"
import headerStyles from "./catalog.module.scss"

const Catalog = () => {

	return (

        <div className={ `uk-section uk-section-medium ${headerStyles.Colorsection}` }>
            <div className={ `uk-container` }>

                <div className={ `uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-2@l uk-child-width-1-2@xl ` } uk-grid="true"> 
                    

                    <div>
                        <div className={ `uk-flex uk-flex-row uk-flex-center uk-flex-middle` } >
                            
                            <img src="/img/dogovor.png" width="430" height="430" alt="Образцы договоров, исковых заявлений"></img>
                            
                        </div>
                    </div>

                    <div>
                        <div>
                            <h3 className={ `${headerStyles.CatalogTitle}` } >Образцы договоров, исковых заявлений</h3>
                            <p className={ `${headerStyles.CatalogDescription}` }>Скачать образцы договоров (в формате .DOCX)</p>
                        </div>

                        <div className={ `uk-flex uk-flex-row uk-flex-left uk-flex-wrap` }>
                            <div>
                                <a href="/doc/Договор купли-продажи жилого помещения.docx" className={ `uk-flex uk-flex-row uk-flex-middle ${headerStyles.CatalogButton}` } >
                                    <img className={ `uk-margin-small-right` } src="/svg/doc.svg" width="22" height="22" uk-svg-pdf="true"></img> 
                                    Договор купли-продажи жилого помещения
                                </a>

                                <a href="/doc/Договор аренды нежилого помещения.docx" className={ `uk-flex uk-flex-row uk-flex-middle uk-margin-small-top ${headerStyles.CatalogButton}` } >
                                    <img className={ `uk-margin-small-right` } src="/svg/doc.svg" width="22" height="22" uk-svg-pdf="true"></img> 
                                    Договор аренды нежилого помещения
                                </a>

                                <a href="/doc/Договор аренды недвижимого имущества.docx" className={ `uk-flex uk-flex-row uk-flex-middle uk-margin-small-top ${headerStyles.CatalogButton}` } >
                                    <img className={ `uk-margin-small-right` } src="/svg/doc.svg" width="22" height="22" uk-svg-pdf="true"></img> 
                                    Договор аренды недвижимого имущества 
                                </a>

                                <a href="/doc/Договор возмездного оказания услуг.docx" className={ `uk-flex uk-flex-row uk-flex-middle uk-margin-small-top ${headerStyles.CatalogButton}` } >
                                    <img className={ `uk-margin-small-right` } src="/svg/doc.svg" width="22" height="22" uk-svg-pdf="true"></img> 
                                    Договор возмездного оказания услуг
                                </a>

                                <a  href="/doc/Договор дарения жилого дома с имуществом.docx" className={ `uk-flex uk-flex-row uk-flex-middle uk-margin-small-top ${headerStyles.CatalogButton}` } >
                                    <img className={ `uk-margin-small-right` } src="/svg/doc.svg" width="22" height="22" uk-svg-pdf="true"></img> 
                                    Договор дарения жилого дома с имуществом
                                </a>

                                <a  href="/doc/Договор дарения доли в праве собственности на земельный участок.docx" className={ `uk-flex uk-flex-row uk-flex-middle uk-margin-small-top ${headerStyles.CatalogButton}` } >
                                    <img className={ `uk-margin-small-right` } src="/svg/doc.svg" width="22" height="22" uk-svg-pdf="true"></img> 
                                    Договор дарения доли в праве собственности на земельный участок
                                </a>

                                <a  href="/doc/Договор дарения доли в праве собственности на квартиру.docx" className={ `uk-flex uk-flex-row uk-flex-middle uk-margin-small-top ${headerStyles.CatalogButton}` } >
                                    <img className={ `uk-margin-small-right` } src="/svg/doc.svg" width="22" height="22" uk-svg-pdf="true"></img> 
                                    Договор дарения доли в праве собственности на квартиру
                                </a>

                                <a  href="/doc/Договор дарения.docx" className={ `uk-flex uk-flex-row uk-flex-middle uk-margin-small-top ${headerStyles.CatalogButton}` } >
                                    <img className={ `uk-margin-small-right` } src="/svg/doc.svg" width="22" height="22" uk-svg-pdf="true"></img> 
                                    Договор дарения
                                </a>

                                <a  href="/doc/Исковое заявление о признании завещания недействительным.docx" className={ `uk-flex uk-flex-row uk-flex-middle uk-margin-small-top ${headerStyles.CatalogButton}` } >
                                    <img className={ `uk-margin-small-right` } src="/svg/doc.svg" width="22" height="22" uk-svg-pdf="true"></img> 
                                    Исковое заявление о признании завещания недействительным
                                </a>

                                <a  href="/doc/Исковое заявление о расторжении договора купли-продажи.docx" className={ `uk-flex uk-flex-row uk-flex-middle uk-margin-small-top ${headerStyles.CatalogButton}` } >
                                    <img className={ `uk-margin-small-right` } src="/svg/doc.svg" width="22" height="22" uk-svg-pdf="true"></img> 
                                    Исковое заявление о расторжении договора купли-продажи
                                </a>

                            </div>
                        </div>
                    </div>

                    
                
                </div> 

            </div>
        </div>
        

	)
}

export default Catalog


