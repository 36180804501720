import React from "react"
import "./otzivMap.module.scss"
import headerStyles from "./otzivMap.module.scss"

const OtzivMap = () => {

	return (

        <div className={ `uk-section uk-section-small` }>
            <div className={ `uk-container` }>

                <h2 className={ `${headerStyles.OtzivMapHeader}` }>Отзывы на картах</h2>

                <div className={ `uk-child-width-1-1@s uk-child-width-1-3@m uk-child-width-1-6@l uk-child-width-1-6@xl uk-margin-medium-top` } uk-grid="true">                  
                   
                    <div>
                        <a href="https://www.google.com/maps/place/%D0%90%D0%B3%D0%B5%D0%BD%D1%82%D1%81%D1%82%D0%B2%D0%BE+%D0%BD%D0%B5%D0%B4%D0%B2%D0%B8%D0%B6%D0%B8%D0%BC%D0%BE%D1%81%D1%82%D0%B8+%D0%90%D0%BB%D0%BC%D0%B0%D0%B7/@55.7914709,49.1337022,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x7e93dcefdd784801!8m2!3d55.7914709!4d49.1358909?hl=ru" className={ `uk-flex uk-flex-column uk-text-center` }>
                            <div className={ `${headerStyles.OtzivMapBox}` }>
                                <img src="/img/otziv1.png" alt="отзыв агентства недвижимости Алмаз"></img>
                            </div>
                            <p className={ `${headerStyles.OtzivMapText}` } >Читать все отзывы</p>
                        </a>
                    </div>

                    <div>
                        <a href="https://yandex.ru/profile/23841168798" className={ `uk-flex uk-flex-column uk-text-center` }>
                            <div className={ `${headerStyles.OtzivMapBox}` }> 
                                <img src="/img/otziv2.png" alt="отзыв агентства недвижимости Алмаз"></img>
                            </div>                          
                            <p className={ `${headerStyles.OtzivMapText}` } >Читать все отзывы</p>
                        </a>
                    </div>

                    <div>
                        <a href="https://go.2gis.com/zqmin" className={ `uk-flex uk-flex-column uk-text-center` }>
                            <div className={ `${headerStyles.OtzivMapBox}` }> 
                                <img src="/img/otziv3.png" alt="отзыв агентства недвижимости Алмаз"></img>
                            </div>                          
                            <p className={ `${headerStyles.OtzivMapText}` } >Читать все отзывы</p>
                        </a>
                    </div>

                    <div>
                        <a href="https://kazan.zoon.ru/realty/kompaniya_almaz_v_vahitovskom_rajone" className={ `uk-flex uk-flex-column uk-text-center` }>
                            <div className={ `${headerStyles.OtzivMapBox}` }> 
                                <img src="/img/otziv4.png" alt="отзыв агентства недвижимости Алмаз"></img>
                            </div>                          
                            <p className={ `${headerStyles.OtzivMapText}` } >Читать все отзывы</p>
                        </a>
                    </div>

                    <div>
                        <a href="https://kazan.flamp.ru/firm/almaz_agentstvo_nedvizhimosti-70000001042392256" className={ `uk-flex uk-flex-column uk-text-center` }>
                            <div className={ `${headerStyles.OtzivMapBox}` }> 
                                <img src="/img/otziv5.png" alt="отзыв агентства недвижимости Алмаз"></img>
                            </div>                          
                            <p className={ `${headerStyles.OtzivMapText}` } >Читать все отзывы</p>
                        </a>
                    </div>

                    <div>
                        <a href="https://www.yell.ru/kazan/com/agentstvo-nedvizhimosti-almaz_12063171/" className={ `uk-flex uk-flex-column uk-text-center` }>
                            <div className={ `${headerStyles.OtzivMapBox}` }> 
                                <img src="/img/otziv6.png" alt="отзыв агентства недвижимости Алмаз"></img>
                            </div>                          
                            <p className={ `${headerStyles.OtzivMapText}` } >Читать все отзывы</p>
                        </a>
                    </div>


                                  
                </div>

            </div>
        </div>
        

	)
}

export default OtzivMap


