import React from "react"
import "./uryslygi.module.scss"
import headerStyles from "./uryslygi.module.scss"

const Uryslygi = () => {

	return (

        <div className={ `uk-section uk-section-medium` }>
            <div className={ `uk-container` }>

                <h2 className={ `${headerStyles.cardHeaders}` }>Популярные юридические услуги</h2>

                <div className={ `uk-grid-match uk-child-width-1-1 uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-3@l uk-child-width-1-3@l uk-flex uk-flex-center uk-margin-medium-top` } uk-grid="true">
                    
                    <div>
                        <a href="/yuridicheskaya-konsultaciya" className={ `uk-flex uk-flex-center ${headerStyles.estateCardLink}` }>
                            <div className={ `uk-card uk-card-default uk-card-body ${headerStyles.estateCard} ` } >

                                <p className={ `${headerStyles.cardTitle} ${headerStyles.cT1}` }>Профессиональный подход</p>
                                
                                <h3 className={ `uk-card-title uk-flex uk-flex-row uk-flex-between uk-flex-middle uk-margin-remove` }  >
                                    <span className={ `uk-margin-remove ${headerStyles.cardHeader}` } >Юридическая консультация</span>
                                </h3>
                                
                                <p className={ `${headerStyles.cardDescription}` }>Это возможность быстро получить консультацию юриста и принять верное решение!</p>

                                <p className={ `${headerStyles.cardPrice}` }>от 1 000 ₽</p>

                                <img className={ `${headerStyles.cardImg}` } src="/img/c1.png"></img>
                                
                            </div>
                        </a>
                    </div>

                    <div>
                        <a href="/yurist-po-nedvizhimosti" className={ `uk-flex uk-flex-center ${headerStyles.estateCardLink}` }>
                            <div className={ `uk-card uk-card-default uk-card-body ${headerStyles.estateCard}` } >

                                <p className={ `${headerStyles.cardTitle} ${headerStyles.cT2}` }>Демократичные цены</p>
                                
                                <h3 className={ `uk-card-title uk-flex uk-flex-row uk-flex-between uk-flex-middle uk-margin-remove` }  >
                                    <span className={ `uk-margin-remove ${headerStyles.cardHeader}` } >Юрист по недвижимостия</span>
                                </h3>
                                
                                <p className={ `${headerStyles.cardDescription}` }>Сопровождение сделок с недвижимостью «под ключ». Проверка объекта недвижимости, подготовка всех необходимых документов</p>

                                <p className={ `${headerStyles.cardPrice}` }>от 15 000 ₽</p>

                                <img className={ `${headerStyles.cardImg}` } src="/img/c2.png"></img>
                                
                            </div>
                        </a>
                    </div>

                    <div>
                        <a href="/sostavlenie-dokumentov" className={ `uk-flex uk-flex-center ${headerStyles.estateCardLink}` }>
                            <div className={ `uk-card uk-card-default uk-card-body ${headerStyles.estateCard}` } >

                                <p className={ `${headerStyles.cardTitle} ${headerStyles.cT3}` }>Оперативное решение задач</p>
                                
                                <h3 className={ `uk-card-title uk-flex uk-flex-row uk-flex-between uk-flex-middle uk-margin-remove` }  >
                                    <span className={ `uk-margin-remove ${headerStyles.cardHeader}` } >Составление документов</span>
                                </h3>
                                
                                <p className={ `${headerStyles.cardDescription}` }>Подготовка любых правовых документов. Наши специалисты – это профессионалы в области договорного права.</p>

                                <p className={ `${headerStyles.cardPrice}` }>от 5 000 ₽</p>

                                <img className={ `${headerStyles.cardImg}` } src="/img/c3.png"></img>
                                
                            </div>
                        </a>
                    </div>

                    <div>
                        <a href="/ispolnitelnoe-proizvodstvo" className={ `uk-flex uk-flex-center ${headerStyles.estateCardLink}` }>
                            <div className={ `uk-card uk-card-default uk-card-body ${headerStyles.estateCard}` } >

                                <p className={ `${headerStyles.cardTitle} ${headerStyles.cT4}` }>Полная конфиденциальность</p>
                                
                                <h3 className={ `uk-card-title uk-flex uk-flex-row uk-flex-between uk-flex-middle uk-margin-remove` }  >
                                    <span className={ `uk-margin-remove ${headerStyles.cardHeader}` } >Исполнительное производство</span>
                                </h3>
                                
                                <p className={ `${headerStyles.cardDescription}` }>Юридические консультации, в том числе письменные, по законодательству, регулирующему исполнительное производство.</p>

                                <p className={ `${headerStyles.cardPrice}` }>от 10 000 ₽</p>

                                <img className={ `${headerStyles.cardImg}` } src="/img/c4.png"></img>
                                
                            </div>
                        </a>
                    </div>

                    <div>
                        <a href="/predstavlenie-interesov-v-sude" className={ `uk-flex uk-flex-center ${headerStyles.estateCardLink}` }>
                            <div className={ `uk-card uk-card-default uk-card-body ${headerStyles.estateCard}` } >

                                <p className={ `${headerStyles.cardTitle} ${headerStyles.cT5}` }>Юридическое сопровождение</p>
                                
                                <h3 className={ `uk-card-title uk-flex uk-flex-row uk-flex-between uk-flex-middle uk-margin-remove` }  >
                                    <span className={ `uk-margin-remove ${headerStyles.cardHeader}` } >Представление интересов в суде</span>
                                </h3>
                                
                                <p className={ `${headerStyles.cardDescription}` }>Доверьте представительство в судах опытному юристу и успех будет на вашей стороне.</p>

                                <p className={ `${headerStyles.cardPrice}` }>от 15 000 ₽</p>

                                <img className={ `${headerStyles.cardImg}` } src="/img/c5.png"></img>
                                
                            </div>
                        </a>
                    </div>

                    <div>
                        <a href="/grazhdanskie-spory" className={ `uk-flex uk-flex-center ${headerStyles.estateCardLink}` }>
                            <div className={ `uk-card uk-card-default uk-card-body ${headerStyles.estateCard}` } >

                                <p className={ `${headerStyles.cardTitle} ${headerStyles.cT6}` }>Соблюдаем сроки</p>
                                
                                <h3 className={ `uk-card-title uk-flex uk-flex-row uk-flex-between uk-flex-middle uk-margin-remove` }  >
                                    <span className={ `uk-margin-remove ${headerStyles.cardHeader}` } >Гражданские споры</span>
                                </h3>
                                
                                <p className={ `${headerStyles.cardDescription}` }>Досудебное урегулировании споров. Составление процессуальных документов. Обжалование судебных решений.</p>

                                <p className={ `${headerStyles.cardPrice}` }>от 10 000 ₽</p>

                                <img className={ `${headerStyles.cardImg}` } src="/img/c6.png"></img>
                                
                            </div>
                        </a>
                    </div>

                </div>

            </div>
        </div>
        
	)
}

export default Uryslygi


