import React from "react"
import "./sdelka.module.scss"
import headerStyles from "./sdelka.module.scss"

const Sdelka = () => {

	return (

        <div className={ `uk-section uk-section-medium uk-margin-medium-top ${headerStyles.Colorsection}` }>
            <div className={ `uk-container` }>

                <h2 className={ `${headerStyles.SdelkaHeader}` }>Направления нашей деятельности</h2>
                <p className={ `${headerStyles.SdelkaHeaderDescription}` } >Наша юридическая компания предлагает грамотное и актуальное решение широкого спектра юридических вопросов - от юридической консультации до полного юридического сопровождения</p>

                    <div className={ `uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-2@l uk-child-width-1-2@xl uk-margin-medium-top` } uk-grid="true">
                            
                        <div className={ `uk-flex uk-flex-row ${headerStyles.SdelkaContent}` }>
                            
                            <img className={ `${headerStyles.SdelkaImg}` } src="/img/cc1.png" alt="Юридическая консультация"></img>

                            
                            <div className={ `uk-margin-small-left` }>
                                <p className={ `${headerStyles.SdelkaTextTitle}` }>Юридическая консультация</p>
                                <a href="/konsultaciya-po-nedvizhimosti" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt=" Консультация по недвижимости" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Консультация по недвижимости
                                </a>
                                <a href="/konsultaciya-po-semejnym-voprosam" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Консультация по семейным вопросам" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Консультация по семейным вопросам
                                </a>
                                <a href="/konsultaciya-po-nasledstvennym-voprosam" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Консультация по наследственным вопросам" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Консультация по наследственным вопросам
                                </a>
                                <a href="/konsultaciya-po-zhilishchnym-voprosam" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Консультация по жилищным вопросам" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Консультация по жилищным вопросам
                                </a>
                                <a href="/konsultaciya-po-trudovym-voprosam" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Консультация по трудовым вопросам" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Консультация по трудовым вопросам
                                </a>
                                <a href="/konsultaciya-po-zemelnym-voprosam" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Консультация по земельным вопросам" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Консультация по земельным вопросам
                                </a>
                                <a href="/konsultaciya-po-kreditnym-voprosam" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Консультация по кредитным вопросам" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Консультация по кредитным вопросам
                                </a>
                                <a href="/konsultaciya-po-ispolnitelnomu-proizvodstvu" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Консультация по исполнительному производству" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Консультация по исполнительному производству
                                </a>
                            </div>
                            

                        </div>

                        <div className={ `uk-flex uk-flex-row ${headerStyles.SdelkaContent}` }>
                            <img className={ `${headerStyles.SdelkaImg}` } src="/img/cc2.png" alt="Сделки с недвижимостью"></img>
                            
                           
                            <div className={ `uk-margin-small-left` }>
                                <p className={ `${headerStyles.SdelkaTextTitle}` }>Сделки с недвижимостью</p>
                                
                                <a href="/oformlenie-sdelok-s-nedvizhimostyu" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Оформление сделок с недвижимостью" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-map="true"></img> 
                                    Оформление сделок с недвижимостью
                                </a>
                                <a href="/proverka-yuridicheskoj-chistoty" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Проверка юридической чистоты" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-map="true"></img> 
                                    Проверка юридической чистоты
                                </a>
                                <a href="/registraciya-prav-sobstvennosti" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Регистрация прав собственности" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-map="true"></img> 
                                    Регистрация прав собственности
                                </a>
                                <a href="/soprovozhdenie-sdelok-s-zemlej" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Сопровождение сделок с землей" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-map="true"></img> 
                                    Сопровождение сделок с землей
                                </a>
                                <a href="/dogovora-kupli-prodazhi" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Договора купли-продажи" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-map="true"></img> 
                                    Договора купли-продажи
                                </a>
                                <a href="/dogovor-arendy-nezhilogo-pomeshcheniya" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Договор аренды нежилого помещения" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-map="true"></img> 
                                    Договор аренды нежилого помещения
                                </a>
                            </div>
                            
                        </div>

                        <div className={ `uk-flex uk-flex-row ${headerStyles.SdelkaContent}` }>
                            <img className={ `${headerStyles.SdelkaImg}` } src="/img/cc4.png" alt="Исполнительное производство"></img>
                            
                            <div className={ `uk-margin-small-left` }>
                                <p className={ `${headerStyles.SdelkaTextTitle}` }>Исполнительное производство</p>
                                
                                <a href="/osvobozhdenie-imushchestva-ot-aresta" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Освобождение имущества от ареста" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Освобождение имущества от ареста
                                </a>
                                <a href="/zhaloba-na-sudebnogo-pristava-ispolnitelya" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Приостановка исполнения решения суда" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Приостановка исполнения решения суда
                                </a>
                                <a href="/zhaloba-na-sudebnogo-pristava-ispolnitelya" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Жалоба на судебного пристава исполнителя" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Жалоба на судебного пристава исполнителя
                                </a>
                                <a href="/vedenie-ispolnitelnogo-proizvodstva" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Ведение исполнительного производства" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Ведение исполнительного производства
                                </a>
                                <a href="/prekrashchenie-ispolnitelnyh-proizvodstv" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Прекращение исполнительных производств" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Прекращение исполнительных производств
                                </a>
                                <a href="/yurist-po-rabote-s-sudebnymi-pristavami" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Юрист по работе с судебными приставами" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Юрист по работе с судебными приставами
                                </a>
                                <a href="/soprovozhdenie-ispolnitel-nogo-proizvodstva" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Сопровождение исполнительного производства" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Сопровождение исполнительного производства
                                </a>
                                <a href="/priostanovlenie-ispolnitelnogo-proizvodstva" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Приостановление исполнительного производства" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Приостановление исполнительного производства
                                </a>
                            </div>
                            
                        </div>

                        <div className={ `uk-flex uk-flex-row ${headerStyles.SdelkaContent}` }>
                            <img className={ `${headerStyles.SdelkaImg}` } src="/img/cc5.png" alt="Представление интересов в суде"></img>
                            
                            
                            <div className={ `uk-margin-small-left` }>
                                <p className={ `${headerStyles.SdelkaTextTitle}` }>Представление интересов в суде</p>
                                
                                <a href="/predstavitelstvo-v-sude" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Представительство в суде" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Представительство в суде
                                </a>
                                <a href="/konsultaciya-yurista-po-sudebnym-delam" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Консультация юриста по судебным делам" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Консультация юриста по судебным делам
                                </a>
                                <a href="/obzhalovanie-resheniya-suda" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Обжалование решения суда" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Обжалование решения суда
                                </a>
                                <a href="/vedenie-dela-v-sude" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Ведение дела в суде" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Ведение дела в суде
                                </a>
                                <a href="/predstavitelstvo-v-mirovom-sude" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Представительство в мировом суде" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Представительство в мировом суде
                                </a>
                                <a href="/predstavitelstvo-v-apellyacionnom-sude" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Представительство в апелляционном суде" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Представительство в апелляционном суде
                                </a>
                                <a href="/predstavitelstvo-v-sude-pervoj-instancii" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Представительство в суде первой инстанции" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Представительство в суде первой инстанции
                                </a>
                                <a href="/predstavitelstvo-v-sude-po-grazhdanskim-delam" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Представительство в суде по гражданским делам" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Представительство в суде по гражданским делам
                                </a>
                            </div>
                            
                        </div>

                        <div className={ `uk-flex uk-flex-row ${headerStyles.SdelkaContent}` }>
                            <img className={ `${headerStyles.SdelkaImg}` } src="/img/cc3.png" alt="Подготовка документов"></img>
                            
                            
                            <div className={ `uk-margin-small-left` }>
                                <p className={ `${headerStyles.SdelkaTextTitle}` }>Подготовка документов</p>
                                
                                <a href="/podgotovka-pisem-zayavlenij-zaprosov" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img  alt="Подготовка писем, заявлений, запросов"className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Подготовка писем, заявлений, запросов
                                </a>
                                <a href="/podgotovka-dogovorov" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Подготовка договоров" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Подготовка договоров
                                </a>
                                <a href="/podgotovka-pretenzij" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Подготовка претензий" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Подготовка претензий
                                </a>
                                <a href="/podgotovka-zhalob" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Подготовка жалоб" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Подготовка жалоб
                                </a>
                                <a href="/podgotovka-processualnyh-dokumentov" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Подготовка процессуальных документов" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Подготовка процессуальных документов
                                </a>
                            </div>
                            
                        </div>

                        <div className={ `uk-flex uk-flex-row ${headerStyles.SdelkaContent}` }>
                            <img className={ `${headerStyles.SdelkaImg}` } src="/img/cc6.png" alt="Трудовые споры"></img>
                            
                            
                            <div className={ `uk-margin-small-left` }>
                                <p className={ `${headerStyles.SdelkaTextTitle}` }>Трудовые споры</p>
                                
                                <a href="/priznanie-uvolneniya-nezakonnym" className={ `${headerStyles.SdelkaCh} uk-flex uk-flex-row uk-flex-middle ${headerStyles.SdelkaHref} ` }>
                                    <img alt="Признание увольнения незаконным" className={ `uk-margin-small-right` } src="/svg/finance.svg" width="18" height="18" uk-svg-finance="true"></img> 
                                    Признание увольнения незаконным
                                </a>
                            </div>
                            
                        </div>          
                       
                    </div>

                    <div className={ `uk-child-width-1-1@s uk-child-width-1-1@m uk-child-width-1-1@l uk-child-width-1-1@xl uk-margin-medium-top` } uk-grid="true">
                            
                        <div className={ `uk-flex uk-flex-row uk-flex-wrap ${headerStyles.SdelkaContent}` }>

                            <a className={ `uk-flex uk-flex-row ${headerStyles.SdelkaLinkOblako}` } href="/arbitrazhnyj-sud">#Арбитражный суд</a>

                            <a className={ `uk-flex uk-flex-row ${headerStyles.SdelkaLinkOblako}` } href="/oformlenie-nedvizhimosti-v-kazani">#Оформление недвижимости в Казани</a>

                            <a className={ `uk-flex uk-flex-row ${headerStyles.SdelkaLinkOblako}` } href="/nasledstvennye-spory">#Наследственные споры</a>

                            <a className={ `uk-flex uk-flex-row ${headerStyles.SdelkaLinkOblako}` } href="/podgotovka-iskovogo-zayavleniya">#Подготовка искового заявления</a>

                            <a className={ `uk-flex uk-flex-row ${headerStyles.SdelkaLinkOblako}` } href="/predstavitelstvo-v-arbitrazhnom-sude">#Представительство в арбитражном суде</a>

                            <a className={ `uk-flex uk-flex-row ${headerStyles.SdelkaLinkOblako}` } href="/predstavitelstvo-v-sudah">#Представительство в судах</a>

                            <a className={ `uk-flex uk-flex-row ${headerStyles.SdelkaLinkOblako}` } href="/predstavitelstvo-zashchita-v-sude">#Представительство защита в суде</a>

                            <a className={ `uk-flex uk-flex-row ${headerStyles.SdelkaLinkOblako}` } href="/predstavlenie-interesov-v-gosudarstvennyh-organah">#Представление интересов в государственных органах</a>

                            <a className={ `uk-flex uk-flex-row ${headerStyles.SdelkaLinkOblako}` } href="/razrabotka-pravovyh-dokumentov">#Разработка правовых документов</a>

                            <a className={ `uk-flex uk-flex-row ${headerStyles.SdelkaLinkOblako}` } href="/registraciya-prava-sobstvennosti">#Регистрация права собственности</a>

                            <a className={ `uk-flex uk-flex-row ${headerStyles.SdelkaLinkOblako}` } href="/sostavlenie-dogovorov">#Составление договоров</a>

                            <a className={ `uk-flex uk-flex-row ${headerStyles.SdelkaLinkOblako}` } href="/sudebnoe-vzyskanie-dolgov">#Судебное взыскание долгов</a>

                            <a className={ `uk-flex uk-flex-row ${headerStyles.SdelkaLinkOblako}` } href="/sudebnye-spory">#Судебные споры</a>

                            <a className={ `uk-flex uk-flex-row ${headerStyles.SdelkaLinkOblako}` } href="/ystnye-i-pismennye-konsultacii">#Устные и письменные консультации</a>

                            <a className={ `uk-flex uk-flex-row ${headerStyles.SdelkaLinkOblako}` } href="/yuridicheskaya-pomoshch">#Юридическая помощь</a>

                            <a className={ `uk-flex uk-flex-row ${headerStyles.SdelkaLinkOblako}` } href="/yuridicheskoe-soprovozhdenie-sdelok">#Юридическое сопровождение сделок</a>

                            <a className={ `uk-flex uk-flex-row ${headerStyles.SdelkaLinkOblako}` } href="/yurist-po-nedvizhimosti">#Юрист по недвижимости</a>

                            <a className={ `uk-flex uk-flex-row ${headerStyles.SdelkaLinkOblako}` } href="/zemelnye-spory">#Земельные споры</a>

                            <a className={ `uk-flex uk-flex-row ${headerStyles.SdelkaLinkOblako}` } href="/zhilishchnye-spory">#Жилищные споры</a>

                        </div>
                    </div>
                                  
                </div>

            </div>

	)
}

export default Sdelka


