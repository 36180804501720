import React from "react"
import "./otzivVideo.module.scss"
import headerStyles from "./otzivVideo.module.scss"

const OtzivVideo = () => {

	return (

        <div className={ `uk-section uk-section-xsmall` }>
            <div className={ `uk-container` } >

                <h2 className={ `${headerStyles.otzivVideoHeader}` }>Видео отзывы</h2>

                <div className={ `uk-grid-match uk-child-width-1-1@l uk-margin-medium-top` } uk-grid="true">
                    <div>
                        <div className={ `uk-flex uk-flex-row uk-flex-center` } >

                            <div uk-slider="autoplay: true; autoplay-interval: 2000;">

                                <div className={ `uk-position-relative` }>

                                    <div className={ `uk-slider-container uk-light` } >
                                        <ul uk-lightbox="true" className={ `uk-slider-items uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-3@l uk-child-width-1-3@xl` } >

                                            <li className={ `${headerStyles.otzivLi}` }>
                                                <a href="https://www.youtube.com/watch?v=dx4HZtnkdd8" data-caption="YouTube" data-attrs="width: 1280; height: 720;" >
                                                    <img src="/img/ov1.png" alt="отзыв агентства недвижимости Алмаз"></img>
                                                </a>
                                                <div className={ `uk-position-center uk-panel` }></div>
                                            </li>

                                            <li className={ `${headerStyles.otzivLi}` }>
                                                <a href="https://www.youtube.com/watch?v=iFot-zqHwMw" data-caption="YouTube" data-attrs="width: 1280; height: 720;" >
                                                    <img src="/img/ov2.png" alt="отзыв агентства недвижимости Алмаз"></img>
                                                </a>
                                                <div className={ `uk-position-center uk-panel` }></div>
                                            </li>

                                            <li className={ `${headerStyles.otzivLi}` }>
                                                <a href="https://www.youtube.com/watch?v=v12-RHylj_0" data-caption="YouTube" data-attrs="width: 1280; height: 720;" >
                                                    <img src="/img/ov4.png" alt="отзыв агентства недвижимости Алмаз"></img>
                                                </a>
                                                <div className={ `uk-position-center uk-panel` }></div>
                                            </li>

                                            <li className={ `${headerStyles.otzivLi}` }>
                                                <a href="https://www.youtube.com/watch?v=bRSzHNuj7z0" data-caption="YouTube" data-attrs="width: 1280; height: 720;" >
                                                    <img src="/img/ov3.png" alt="отзыв агентства недвижимости Алмаз"></img>
                                                </a>
                                                <div className={ `uk-position-center uk-panel` }></div>
                                            </li>

                                            <li className={ `${headerStyles.otzivLi}` }>
                                                <a href="https://www.youtube.com/watch?v=BOv_bmnB7P4" data-caption="YouTube" data-attrs="width: 1280; height: 720;" >
                                                    <img src="/img/ov5.png" alt="отзыв агентства недвижимости Алмаз"></img>
                                                </a>
                                                <div className={ `uk-position-center uk-panel` }></div>
                                            </li>

                                            <li className={ `${headerStyles.otzivLi}` }>
                                                <a href="https://www.youtube.com/watch?v=BkHS5dfNnwU" data-caption="YouTube" data-attrs="width: 1280; height: 720;" >
                                                    <img src="/img/ov6.png" alt="отзыв агентства недвижимости Алмаз"></img>
                                                </a>
                                                <div className={ `uk-position-center uk-panel` }></div>
                                            </li>

                                            <li className={ `${headerStyles.otzivLi}` }>
                                                <a href="https://www.youtube.com/watch?v=qo9lanAmar4" data-caption="YouTube" data-attrs="width: 1280; height: 720;" >
                                                    <img src="/img/ov7.png" alt="отзыв агентства недвижимости Алмаз"></img>
                                                </a>
                                                <div className={ `uk-position-center uk-panel` }></div>
                                            </li>
                                            
                                        </ul>
                                    </div>                                       

                                </div>

                            </div>
                        
                        </div>
                    </div>
                </div>

            </div>
        </div>

	)
}

export default OtzivVideo


